exports.components = {
  "component---src-components-layouts-blog-layout-js": () => import("./../../../src/components/layouts/BlogLayout.js" /* webpackChunkName: "component---src-components-layouts-blog-layout-js" */),
  "component---src-pages-alternative-to-jobscan-js": () => import("./../../../src/pages/alternative-to-jobscan.js" /* webpackChunkName: "component---src-pages-alternative-to-jobscan-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-california-privacy-rights-js": () => import("./../../../src/pages/california-privacy-rights.js" /* webpackChunkName: "component---src-pages-california-privacy-rights-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-services-js": () => import("./../../../src/pages/terms-services.js" /* webpackChunkName: "component---src-pages-terms-services-js" */),
  "component---src-pages-third-party-js": () => import("./../../../src/pages/third-party.js" /* webpackChunkName: "component---src-pages-third-party-js" */)
}

